import { createApp } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import Router from "@/router/index.js";
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

Router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

createApp(App)
  .use(Antd)
  .use(Router)
  // .use(vConsole)
  .mount("#app");
