import { createRouter, createWebHistory } from "vue-router";
const home = () => import("@/components/Home");
const concat = () => import("@/components/concat/Concat");
const about = () => import("@/components/about/About");
const courseShop = () => import("@/components/courseShop/CourseShop");
const courseDetail = () => import("@/components/courseDetail/CourseDetail");
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: home,
      meta: {
        title: "官网首页",
      },
    },
    {
      path: "/concat",
      component: concat,
      meta: {
        title: "联系我们",
      },
    },
    {
      path: "/about",
      component: about,
      meta: {
        title: "关于我们",
      },
    },
    {
      path: "/courseShop",
      component: courseShop,
      meta: {
        title: "课程商店",
      },
    },
    {
      path: "/courseDetail/:courseId",
      component: courseDetail,
      meta: {
        title: "课程详情",
      },
    },
  ],
});

export default router;
